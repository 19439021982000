.landing {
  text-align: center;
  margin: 5em 10% 0;
}

.landing img {
  width: 60%;
  margin-bottom: 1.5em;
  vertical-align: middle;
}

.intro-text {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  text-align: center;
  font-size: 1.3em;
  margin: 0;
}

.latest-projects-container {
  margin: 6em 0 3em;
  padding-bottom: 1.5em;
  background-color: #e4d4d4;
  transform: skewY(-5deg);
}

.latest-projects-container > * {
  padding-top: 1.5em;
  transform: skewY(5deg);
  text-align: center;
}

.latest-projects-container h1 {
  font-family: "DM Serif Display", serif;
  font-weight: 400;
}

.projects-scroll {
  padding: 2em 0;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  align-self: center;
}

.projects-scroll::-webkit-scrollbar {
  display: none;
  width: 5px;
}

.scroll-icon {
  text-align: center;
  width: 50px;
  margin-top: -6em;
}

.project-details {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background-color: #f8f8f8;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.3s ease;
}

@media only screen and (min-width: 768px) {
  .latest-projects-container {
    padding-bottom: 4%;
  }
  .project-title {
    font-size: 1.6em;
  }
  footer {
    display: flex;
    justify-content: center;
  }
  .socials {
    text-align: center;
    margin-bottom: 2em;
    order: 2;
  }
  .socials-list img {
    width: 10%;
  }
  .footer-logo {
    width: 40%;
  }
}

@media only screen and (min-width: 875px) {
  .hide-desktop {
    display: none;
  }
  .show-desktop {
    display: block;
  }
  nav {
    position: relative;
    z-index: 5;
  }
  nav ul {
    position: inherit;
    width: 100%;
    background: none;
    height: auto;
    padding-top: 0;
    margin: -1em 0 0;
    text-align: center;
  }
  nav ul li {
    display: inline;
  }
  nav ul li a {
    display: inline-block;
    color: #000;
    background-color: inherit;
    margin: 0 1em;
    font-size: 1em;
  }
  nav ul li:hover,
  .active {
    font-weight: 600;
    font-style: italic;
  }
  .logo {
    width: 70%;
    margin: 0 auto;
  }
  .project-title {
    font-size: 2em;
  }
  .landing img {
    margin: auto 3em;
    width: 35%;
  }
  .landing p {
    margin: auto 5%;
  }
  .projects-scroll {
    width: 100%;
  }
  .landing {
    display: inline-flex;
  }
  .socials-list img {
    width: 15%;
  }
  .socials-list a {
    font-size: 1.2em;
  }
  .footer-logo {
    width: 50%;
  }
}

@media only screen and (min-width: 1024px) {
  .landing img {
    width: 30%;
  }
  .landing p {
    font-size: 1.7em;
  }
}

@media only screen and (min-width: 1216px) {
  .landing p {
    font-size: 1.7em;
  }
}

@media only screen and (min-width: 1440px) {
  .landing img {
    width: 25%;
  }
}
