* {
  padding: 0;
  margin: 0;
}

body {
  height: 100%;
}

@font-face {
  font-family: "Signature";
  src: local("Signature"),
    url(../fonts/BrittanySignature-MaZx.ttf) format("truetype");
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.signature-font {
  font-family: "Signature";
  font-size: 1.2em;
  position: absolute;
  top: 75%;
  right: 10%;
}

.hide-mobile {
  display: none;
}

.logo-container {
  position: relative;
  margin-top: 2em;
  z-index: 1;
}

.logo {
  width: 100%;
}

.logo,
.socials-info h2 {
  font-family: "DM Serif Display", serif;
  font-weight: 400;
}

.menu-icon {
  position: absolute;
  top: calc(50% - 10px);
  right: 3em;
  width: 20px;
  cursor: pointer;
}

nav ul {
  position: fixed;
  height: 100%;
  top: 0;
  right: 0;
  width: 100%;
  /* background-color: rgb(128, 128, 128); */
  background-color: #93bdbc;
  font-family: "Open Sans", sans-serif;
  font-size: 1.2em;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 5;
}

nav ul li {
  list-style: none;
}

nav ul li a {
  display: block;
  margin: 1em 0;
  text-align: center;
  text-decoration: none;
  color: #000;
  font-size: 1em;
  font-weight: 300;
}

nav ul li:hover,
.active {
  font-weight: 600;
  font-style: italic;
}

.exit-btn {
  padding-top: 1em;
  cursor: pointer;
  text-align: center;
}

.exit-btn img {
  width: 0.6em;
}

footer {
  padding: 0 3em 1em 0;
}

footer .socials-info {
  text-align: end;
}

footer .socials-info h2 {
  margin-bottom: 1em;
}

footer ul li {
  display: inline;
  font-size: 1em;
  font-family: "Open Sans", sans-serif;
}

footer ul li a {
  text-decoration: none;
  color: #000;
  margin-right: 0.5em;
}

footer ul img {
  width: 8%;
  vertical-align: middle;
  padding: 5px 0;
}

footer .footer-logo {
  text-align: end;
  align-items: center;
}

.footer-logo img {
  width: 30%;
  margin: 2em auto 0;
  min-width: 113px;
}

@media only screen and (min-width: 768px) {
  .signature-font {
    font-size: 1.3em;
  }
  footer {
    display: flex;
    justify-content: center;
    padding-right: 0;
  }
  footer .socials-info {
    text-align: end;
    /* margin-bottom: 2em; */
    order: 2;
  }
  footer ul img {
    width: 10%;
  }
  footer .footer-logo {
    text-align: start;
    position: relative;
    width: 50%;
  }
  .footer-logo img {
    width: 40%;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

@media only screen and (min-width: 875px) {
  .signature-font {
    font-size: 1.4em;
  }
  .hide-desktop {
    display: none;
  }
  .show-desktop {
    display: block;
  }
  nav {
    position: relative;
    z-index: 5;
  }
  nav ul {
    position: inherit;
    width: 100%;
    background: none;
    height: auto;
    padding-top: 0;
    margin: -1em 0 0;
    text-align: center;
  }
  nav ul li {
    display: inline;
  }
  nav ul li a {
    display: inline-block;
    color: #000;
    background-color: inherit;
    margin: 0 1em;
    font-size: 1em;
  }
  nav ul li:hover,
  .active {
    font-weight: 600;
    font-style: italic;
  }
  .logo {
    width: 70%;
    margin: 0 auto;
  }
  footer ul img {
    width: 10%;
  }
  footer ul a {
    font-size: 1.2em;
  }
  .footer-logo img {
    width: 30%;
  }
}

@media only screen and (min-width: 1024px) {
  .signature-font {
    font-size: 1.5em;
  }
  nav ul {
    font-size: 1.2em;
  }
  .footer > div {
    margin: 6em;
  }
  .footer-logo img {
    max-height: 50px;
  }
}

@media only screen and (min-width: 1216px) {
  .signature-font {
    right: 12%;
  }
}
