.individual-project {
  display: inline-block;
  max-width: 300px;
  margin: 0 1em;
}

.individual-project img {
  display: block;
  height: 100%;
  max-height: 200px;
  max-width: 200px;
  object-fit: cover;
  border-radius: 20%;
  box-shadow: 10px 10px 6px rgba(0, 0, 0, 0.16);
}

.project-name {
  font-family: "Open Sans", sans-serif;
}

@media only screen and (min-width: 875px) {
  .individual-project {
    margin: 0 2em;
  }
  .individual-project img {
    max-height: 300px;
    max-width: 300px;
  }
}
