.language-icons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 10px;
}

.language-icons img {
  margin: 5px 10px;
  height: 40px;
}

.projects footer {
  background-color: #e4d4d4;
  padding-top: 2em;
}

.section-headers {
  text-align: center;
  font-family: "Roboto Slab", serif;
  font-weight: 300;
}

.general-projects-container > * {
  text-align: center;
}

@media only screen and (min-width: 1024px) {
  .language-icons img {
    height: 50px;
  }
}
